import Header from "./header";
import Footer from "./footer";
import { useEffect, useState } from "react";
import MetamaskFunctions from "../utils/metamask-functions";

export default function Mint() {
  const [creditCard, setCreditCard] = useState(false);

  const setValueActive = (e) => {
    let values = document.querySelectorAll(".box .value div");
    let customInputValue = document.querySelector(
      ".box .value .custom-value input"
    );
    for (let i = 0; i < values.length; i++) {
      values[i].classList.remove("selected");
    }
    e.target.classList.add("selected");
    customInputValue.value = e.target.innerHTML;
  };

  const valueHandle = (e) => {
    if (Number(e.target.value) > Number(e.target.max)) {
      e.target.value = e.target.max;
    } else if (Number(e.target.value) < Number(e.target.min)) {
      e.target.value = e.target.min;
    }
  };

  return (
    <>
      <section className="mint">
        <div className="container">
          <div className="background"></div>
          <div className="grid columns-12">
            <h1 className="title">MINT/BUY NFT</h1>
            <div className="box">
              <h1>Please select the amount of NFTs</h1>
              <div className="value">
                <div className="selected" onClick={setValueActive}>
                  1
                </div>
                <div onClick={setValueActive}>2</div>
                <div onClick={setValueActive}>3</div>
                <div onClick={setValueActive}>4</div>
                <div onClick={setValueActive}>5</div>
                <h4>Or choose another value</h4>
                <div className="custom-value">
                  <input
                    type="number"
                    min="1"
                    max="100"
                    defaultValue={1}
                    onChange={valueHandle}
                  ></input>
                </div>
              </div>
              <div className="buttons">
                <div
                  className="button gradient"
                  onClick={() => {
                    MetamaskFunctions.sendTransaction();
                  }}
                >
                  Pay with ETH
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
