import FridaABI from "./FridaAbi";

var meta_account = null;
var meta_chain = null;
var meta_response = null;

const connect = () => {
    window.ethereum
        .request({
            method: 'eth_requestAccounts',
            params: [],
        })
        .then((res) => { console.log('request accounts', res); meta_account = res })
        .catch((e) => console.log('request accounts ERR', e));
};

const addEthereumChain = () => {
    window.ethereum
        .request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x89',
                    chainName: 'Polygon',
                    blockExplorerUrls: ['https://polygonscan.com'],
                    nativeCurrency: { symbol: 'MATIC', decimals: 18 },
                    rpcUrls: ['https://polygon-rpc.com/'],
                },
            ],
        })
        .then((res) => console.log('add', res))
        .catch((e) => console.log('ADD ERR', e));
};

const MetamaskInit = () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    provider.on("network", (newNetwork, oldNetwork) => {
        if (oldNetwork) { window.location.reload(); }
    });

    window.ethereum.on('chainChanged', (chain) => {
        console.log(chain);
        meta_chain = chain;
    });
    window.ethereum.on('accountsChanged', (accounts) => {
        console.log(accounts);
        meta_account = accounts?.[0];
    });

}

const getMetaAccout = () => meta_account?.[0];

function disclaimer() {
    var msg = "";
    msg += " ";
    msg += "---------- DECLARATION ----------\r\n";
    msg += "By sending ETH to this contract you declare that: \r\n ";
    msg += "1.You are NOT entering into an investment contract  \r\n ";
    msg += "2.You do NOT live in the U.S.A. or other country with unclear/oppressive Crypto regulations \r\n ";
    msg += "3.You hereby indemnify the developers/owners of any liability as a result of using this site/contract \r\n ";

    alert(msg);

}

const sendTransaction = async (setResponse) => {
    var amount = Number(document.querySelector('.box .value .custom-value input').value);

    //     const fridanftaddress = "0xA347D6A871E12725Ab615F79BfFF3C5F97DeB910";
    //     // const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    //     const signer = provider.getSigner();
    //     const fridanft = new ethers.Contract(fridanftaddress, FridaABI, signer);
    //     var mintfeex = await fridanft.mintFee();

    // //     // disclaimer();

    //     let overrides = { value: (mintfeex * amount).toString(), gasLimit: ethers.utils.hexlify(amount * 165000) };
    //     let tx = await fridanft.mintNFT(amount, overrides);
    //     const receipt = await tx.wait();


    //     console.log(receipt);
    // 0x0000000000000000000000000000000000000000
    // 0xA347D6A871E12725Ab615F79BfFF3C5F97DeB910

    // 0x8553935666f348CAd9492780c1a6cC0794F043D0

    const totalValue = ('0x29A2241AF62C0000' * amount).toString(16);

    const to = '0xA347D6A871E12725Ab615F79BfFF3C5F97DeB910';
    const transactionParameters = {
        from: window.ethereum.selectedAddress, // must match user's active address.
        to, // Required except during contract publications.
        data:"0x926427440000000000000000000000000000000000000000000000000000000000000002",
        value: totalValue, // Only required to send ether to the recipient from the initiating external account.
        gas: ethers.utils.hexlify(amount * 165000)
    };

    try {
        // txHash is a hex string
        // As with any RPC call, it may throw an error
        const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });

        setResponse(txHash);
    } catch (e) {
        console.log(e);
    }


};


const MetamaskFunctions = {
    MetamaskInit,
    sendTransaction,
    addEthereumChain,
    connect,
    getMetaAccout
}

export default MetamaskFunctions;