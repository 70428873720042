export default function TermsOfService() {


    return (
        <section className="terms-of-service">
            <div className="container">
                <div className="grid columns-12">
                <h1 className="title">Terms Of Service</h1> 
                <div className="sub-title">
                    <p>Home </p>
                    <p> / </p>
                    <p> Terms Of Service</p>
                </div>
                <div className="content">
                    <p>Frida.nft, hereby to be referred to as the “Company” allows you to participate, by buying and selling non-fungible tokens (“NFTs”) and is made available to you by the company.</p>
                    <p>IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND FRIDA.NFT AND ANY SUBSIDIARIES OR PARENT COMPANIES THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY “LEGAL PROCEEDINGS” BELOW FOR DETAILS REGARDING ARBITRATION.</p>
                    <p>IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT INSTALL, COPY, OR USE OUR SERVICES. WITH LIMITED EXCEPTION, ANYTHING YOU PURCHASE FROM US IS NON-REFUNDABLE.</p>
                    <h3>1. Acceptance</h3>
                    <p>By clicking the “Mint” button, completing the account registration process and using the Frida.nft domain at fridanft.org or any of the company’s links, you confirm that you understand and agree to these terms and conditions and the company’s privacy policy, together with any documents that may be expressly referred to and are incorporated by reference (“Terms”).</p>
                    <p>These Terms constitute a legal agreement between you and the Company and govern your access to and use of the Company, including any content, functionality, and services offered on or through the Company/NFT (the “Site”).</p>
                    <p>The Company reserves the right to change or modify these terms at any time and at our sole discretion. You agree and understand that by accessing or using the Company and the Site following any change to these Terms, you are regarded as having agreed to the revised Terms.</p>
                    <h3>2. Definitions</h3>
                    <p>In these Terms: “Non-fungible token” “NFT”: Non-fungible token is a commercial term to describe items like your furniture, a picture image file, or your laptop. These things are not interchangeable for other items because they have unique properties. NFTs are tokens that we can use to represent ownership of unique items. They let us tokenize things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain – no one can modify the record of ownership or copy/paste a new NFT into existence.</p>
                    <p>“Fungible items”: on the other hand, can be exchanged because their value defines them rather than their unique properties. For example, ETH or dollars are fungible because 0.1 ETH / $1 USD is exchangeable for another 0.1 ETH / $1 USD.</p>
                    <p>“Applicable Law”: any law, rule, statute, subordinate legislation, regulation, by-law order, ordinance, protocol, code, guideline, treaty, policy, notice, direction or judicial, arbitral, administrative, ministerial or departmental judgment, award, decree, treaty, directive, or other requirement or guideline published or in force at any time which applies to or is otherwise intended to govern or regulate any person (including all parties to this Terms), property, transaction, activity, event or other matter, including any rule, order, judgment, directive or other requirement or guideline issued by any governmental or regulatory authority; </p>
                    <p className="text-bold">“Sale Item” means any one or more of the following without limitation:</p>
                    <p>(a) any art (including without limitation, designs, drawings, prints, images in any form or media, including without limitation videos and photographs);</p>
                    <p>(b) audio files;</p>
                    <p>(c) collectibles;</p>
                    <p>(d) memorabilia;</p>
                    <p>(e) game assets.</p>
                    <p>"we/us/our" means the Company;</p>
                    <p>"you/your" means the user of the Company or site;</p>
                    <h3>3. Eligibility</h3>
                    <p>The Company has sole and absolute discretion to allow or disallow your access to the Company or site.</p>
                    <p>By agreeing to these Terms, you represent and warrant that:</p>
                    <p className="item-list">1. You are at least 18 years of age;</p>
                    <p className="item-list">2. You have the full right, power, and authority to agree to these Terms;</p>
                    <p className="item-list">3. You are not subject to any financial sanctions, embargoes or other restrictive measures imposed by the United Nations, European Union, any EU country, UK Treasury or US Office of Foreign Assets Control (OFAC), or any governmental authority in any jurisdiction in which the Company is available;</p>
                    <p className="item-list">4. You are not a citizen or resident of any of the following countries: United States of America, Belarus, Central African Republic, Cote d’Ivoire (Ivory Coast), Cuba, Democratic Republic of the Congo, Iran, Iraq, Lebanon, Liberia, Libya, Myanmar (Burma), North Korea, Somalia, Sudan, Syria, Zimbabwe.</p>
                    <p className="item-list">5. You are not impersonating any other person;</p>
                    <p className="item-list">6. You will not use the Company’s services if any Applicable Laws in your country prohibit you from doing so in accordance with these Terms;</p>
                    <p className="item-list">7. You are compliant with all Applicable Laws to which you are subject;</p>
                    <p className="item-list">8. You have read, understood and agreed to our Privacy Notice and Cookie Policy.</p>
                    <h3>4. The Company Frida.nft Account</h3>
                    <p>You must create an account (“Account”) to use the Company’s services. To create an account, we will require you to provide certain information about yourself and we may, in our sole discretion, require you to provide further information and/or documents at any stage during your use of the Company Platform. We may, in our sole discretion, refuse, decline, suspend or disable your access or use of the Company services.</p>
                    <h3>5. Changes to the Company</h3>
                    <p>We may in our absolute and sole discretion change, update, amend, remove, or discontinue any part of the Site, the services and the Content at any time without prior notice to you.</p>
                    <h3>6. Fees</h3>
                    <p>By buying and selling NFTs, on the Company, you agree to pay all applicable fees on the checkout screen at the time of your purchase. You authorize the Company to automatically deduct fees directly from payments to you and/ or add fees to your payments to the Company where applicable.</p>
                    <h3>7. Your Use of the Company services and Conduct</h3>
                    <p>We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Site and Content. Our grant of such license is subject to the following conditions. You undertake not to (and shall not, knowingly or otherwise, authorize, allow or assist any other party to):</p>
                    <p className="item-list">1. Use the Company, Site, or your Account to conduct electronic spamming or otherwise distribute any unsolicited or unauthorized advertising, promotional or marketing material, junk or chain messages;</p>
                    <p className="item-list">2. Use the Company, Site, or your Account to perform unlawful activities that violates any Applicable Laws (including but not limited to money laundering, terrorism financing and/or fraudulent activities) or immoral activities;</p>
                    <p className="item-list">3. Use the Company, Site, or your Account to engage in any activity which operates to defraud the Company, other users, or any other person, or to provide any false, inaccurate, or misleading information to the Company;</p>
                    <p className="item-list">4. Use the Company, Site, or your Account to upload content that contains or is infected with viruses, malicious codes, Trojan horses, is immoral or illegal or contains any other harmful or deleterious program;</p>
                    <p className="item-list">5. Modify or adapt the whole or any part of the Company and Site or incorporate the Site into any other program or application;</p>
                    <p className="item-list">6. Disassemble, decompile, reverse-engineer or otherwise attempt to derive the source code, object code, underlying concepts, ideas and algorithms of the Site or any components thereof;</p>
                    <p className="item-list">7. Use the Company, Site, or your Account in any manner that would lead to infringement of our, our Affiliates’ or any third party’s intellectual property rights, including without limitation any copyright, patent or trademark. You undertake not to take or attempt to take any action or claim ownership of any property that infringes or would infringe upon our intellectual property interests;</p>
                    <p className="item-list">8. Use the Company, Site, or your Account in a way that could damage, disable, impair or compromise the Site or the provision of the Company or interfere with other users or affect the reputation of the Company;</p>
                    <p className="item-list">9. To take any action to gain or attempt to gain unauthorized access to the account or wallets of other users;</p>
                    <p className="item-list">10. Take any action that imposes an unreasonable or disproportionately large burden or load on the Company infrastructure (including, but without limitation to our servers, networks, data centers and related or like equipment) and detrimentally interfere with, intercept or expropriate any system, data or information belonging to other users of the Company ;</p>
                    <p className="item-list">11. Engage in any other activities deemed inappropriate by us or which is in contravention of these Terms or any Applicable Laws;</p>
                    <p className="item-list">12. Provide false, inaccurate, incomplete or misleading information to the Company or any of its Affiliates or third-party services providers; and/or</p>
                    <p className="item-list">13. Use the Company, Site, or your Account to engage in any lottery, bidding fee auctions, contests, sweepstakes, or other games of chance.</p>
                    <h3>8. User Content</h3>
                    <p>In the event, the Company allows (i) users to create a profile where they can post information about themselves, display their NFTs, and sell NFTs they own, and (ii) artists or creators of NFTs (“Creator”) to put their NFTs up for sale (together the “User Content”), any information you post on the Site as a Creator will be considered non-confidential.</p>
                    <p>By providing any User Content on the Site, you grant us and our affiliates and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, retransmit, publish, broadcast, and otherwise disclose to third parties any such material for any purpose. You represent and warrant that (1) you own and control all rights in and to your User Content and have the right to grant such licenses to us and our affiliates and our respective licensees, successors, and assigns; and</p> 
                    <p>(2) all of your User Content do and will comply with these Terms.</p>
                    <p>You understand and agree that you are responsible for any User Content you submit or contribute, and you, and not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the content, accuracy, or appropriateness of any User Content posted by you or any other user on the Site.If you are a Creator, you hereby grant the Company the right to use your name and image for marketing or promotional purposes and agree that we may use or modify images from the NFTs that you create for marketing or promotional purposes. You also agree that we can use your biography and other public information about you to promote the NFTs that you create.</p>
                    <p>If you are a Creator, you agree that you will not infringe on the intellectual property of others and will not coordinate pricing of any NFTs with other Creators. We reserve the right, in our absolute sole discretion, to prohibit you from uploading any NFTs to the Site. We are not required to monitor any User Content, but we may in our sole discretion, remove any User Content at any time and for any reason without notice. The Company may monitor the User Content to detect and prevent fraudulent activity or violation of these Terms.</p>
                    <h3>9. Intellectual Property</h3>
                    <p>Unless otherwise indicated by us, and except to the extent of the User Content, the Site, all content, and other materials contained therein, including, without limitation, the Company logo, and all designs, text graphics, pictures, information, data, software, and files relating to the Company (the “Content”) are the proprietary property of the Company or our affiliates, licensors, or users, as applicable.</p>
                    <p>The Company logo and any of the Company product or service names, logos, or slogans that may appear on the Site or elsewhere are the proprietary property of the Company and may not be copied, imitated or used, in whole or in part, without our prior written permission.</p>
                    <p>Unless otherwise stated, you may not use any Content without our express written permission.</p>
                    <p>We reserve the right to suspend or terminate any Account that has actually or allegedly infringed upon any person’s intellectual property rights.</p>
                    <h3>10. Your Ownership of the NFT</h3>
                    <p>Apart from the Content, all other copyrights, trademarks, product names, and logos on the Site relating to and including the NFTs and User Content, are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable intellectual property right owner.</p>
                    <p>When you buy an NFT on the Company, you own the NFT and have the right to sell or give away the NFT. If the NFT is associated with a Sale Item, you will have a worldwide, perpetual, exclusive, transferable, license to use, copy, and display the Sale Item for your NFT, for so long as you own the NFT, solely for the following purposes: (a) for your own personal, non-commercial use; (b) as part of the Company that permits the purchase, sale and display of your NFT; (c) as part of a third party website or application that permits the inclusion, involvement, storage, or participation of your NFT.</p>
                    <p>Without limiting the foregoing, if you believe that third-party material hosted by the Company infringes your copyright or trademark rights, please file a notice of infringement by contacting: team@fridanft.org</p>
                    <h3>11. Data Protection / Privacy.</h3>
                    <p>By using the Company, you confirm that you have read and understood our Privacy Notice and understand how we collect, use, disclose and share amongst ourselves your Personal Data and disclose such Personal Data to our authorized service providers and relevant third parties. We will only share your Personal Data in order to facilitate and administer your use of the Company or otherwise if required by law. Such data controller will manage and protect your personal data in accordance with all applicable data protection laws. For full and comprehensive information about when and why we collect personal information about you, how we use it, the conditions under which we may disclose it and how we keep it secure, please refer to our Privacy Notice, which is accessible at https://frida.nft.org/.</p>
                    <p>We reserve the right at any time to satisfy our internal requirement as to your Personal Data (for example, by requesting relevant original documents) including for the purposes of preventing fraud and/or anti-money laundering and counter-terrorist financing purposes.</p>
                    <h3>12. Limitation of Services / Account Closure / Termination</h3>
                    <p>We reserve the right, without notice and in our sole discretion, to terminate or suspend your access to or use of the Site and any Content and/or close your Account, at any time for any reason but in particular, if we suspect in our sole discretion that</p>
                    <p className="item-list">1. your Account is being used for illegal activity;</p>
                    <p className="item-list">2. you have concealed or provided false information;</p>
                    <p className="item-list">3. you have engaged in fraudulent activity; and/or (iv) you have engaged in activity in violation of these Terms.</p>
                    <p>If the Company is holding funds in your Account and has no record of your use of the Company for several years, we may be required, upon passage of applicable time periods, to report these funds as unclaimed property in accordance with the abandoned property and escheat laws. If this occurs, we will use reasonable efforts to give you written notice. If you fail to respond within seven business days or the period as required by Applicable Law, we may be required to deliver any such funds to the applicable jurisdiction as unclaimed property. We reserve the right to deduct a reasonable administrative fee from such unclaimed funds, as permitted by Applicable Law.</p>
                    <h3>13. Risks</h3>
                    <p>You understand and agree that your access and use of the Company services is subject to certain risks including without limitation:</p> 
                    <p className="item-list">1. Price and liquidity of blockchain assets, including the NFTs, are extremely volatile and may be subject to fluctuations.</p>
                    <p className="item-list">2. Fluctuations in the price of other digital assets could materially and adversely affect the NFTs;</p>
                    <p className="item-list">3. Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the NFTs;</p>
                    <p className="item-list">4. NFTs are not legal tender and are not back by any government;</p>
                    <p className="item-list">5. Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions may not be recoverable.</p>
                    <p className="item-list">6. The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for permanent or total loss of value should the market for NFTs disappear.</p>
                    <p className="item-list">7. NFTs are subject to the risk of fraud, counterfeiting, cyber-attacks and other technological difficulties which may prevent access to or use of your NFTs.</p>
                    <p>You understand and agree that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself. The Company does not give any advice or recommendations regarding the NFTs. You understand and agree that you access and use the Company at your own risk. You understand and agree that the Company will not be responsible for any communication failures, disruptions, errors, or distortions you may experience when using the NFTs or the Company services.</p>
                    <h3>14. Taxes</h3>
                    <p>You agree that you are solely responsible for determining what, if any, taxes apply to your NFT transactions on the NFT the Company Platform. Neither the Company nor any other of the Company Parties, as defined below, is responsible for determining the taxes that may apply to your NFT transactions.</p>
                    <h3>15. Disclaimers</h3>
                    <p>Creators may engage in promotion of their respective User Content, including without limitation their Sale Item, through various communications channels such as their social media accounts. The Company is not responsible for any such communications and/or promotional activities carried out by the Creators and will not be liable to you in relation to any such communications and/or promotional activities.</p>
                    <p>You bear full responsibility for verifying the identity, legitimacy, and authenticity of assets you purchase on the Company site.</p>
                    <p>Except as expressly provided to the contrary in writing by the Company, the Site, content contained therein, and the NFTs listed therein are provided on an “as is” and “as available” basis without warranties or conditions of any kind, either express or implied. The Company (and its suppliers) make no warranty that the Site will (1) meet your requirements; (2) be available on an uninterrupted, timely, secure, or error-free basis; or (3) be accurate, reliable, complete, legal, or safe.</p>
                    <p>The Company will not be liable for any loss of any kind from any action taken or taken in reliance on material or information contained on the Site. The Company does not represent or warrant that any content on the Site is accurate, complete, reliable, current or error-free.</p>
                    <p>While the Company attempts to make your access to and use of the Site and content safe, the Company does not represent or warrant that the Site, content, any NFTs listed on the Site or any other part of the Site or the Company sire are free of viruses or other harmful components. We cannot guarantee the security of any data that you disclose online. You acknowledge and accept the inherent security risks of providing information and dealing online over the Internet. We will not be responsible for any breach of security unless it is due to our gross negligence.</p>
                    <p>We will not be responsible or liable to you for any loss and take no responsibility for, and will not be liable to you for, any use of the NFTs including but not limited to, any losses, damages, or claims arising from: (1) user error such as if you forget your password(s), incorrect transactions, or mistyped addresses; (2) server failure or data loss; (3) corrupted wallet files; (4) loss of NFTs.</p>
                    <p>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE AND CONTENT CONTAINED THEREIN. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                    <h3>16. Limitation of Liability</h3>
                    <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SITE, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF THE SITE, PRODUCTS OR THIRD-PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS AND USE OF THE SITE, CONTENT NFTS OR ANY PRODUCT OR SERVICES PURCHASES ON THE SITE EXCEED US$100. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF THE COMPANY FOR PERSONAL INJURY CAUSED BY THE COMPANY’S NEGLIGENCE OR ANY INJURY CAUSED BY THE COMPANY’S FRAUD OR FRAUDULENT MISREPRESENTATION.</p>
                    <h3>17. Indemnification</h3>
                    <p>To the fullest extent permitted by Applicable Law, you agree to indemnify, defend and hold harmless the Company and our past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively the “ Company Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Site, Content or NFTs, (b) your breach of these Terms, and (c) your breach or violation of the rights of a third party, including another user or third party service provider. You agree to promptly notify the Company of any third party Claims and cooperate with the Company Parties in defending such Claims. You further agree that the Company Parties shall have control of the defense or settlement of any third party Claims.</p>
                    <p>THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES THAT MAY BE SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND THE COMPANY.</p>
                    <h3>18. Amendment and Variation</h3>
                    <p>These Terms may from time to time be updated or amended. We will post any such updates on the Site. Such updated Terms as posted will take effect immediately unless otherwise indicated. You should regularly check the Site to inform yourself of any such changes. In addition, we may at any time change, add or remove any feature or functionality of the Site without prior notice. By continuing to use the Company services and/or the Site after any such changes have taken effect, you are indicating your acceptance of the updated or amended Terms as well as your acceptance of the updated Site. If you do not wish to be bound by any changes or amendments to these Terms then you should stop using the Company and Site immediately.</p>
                    <h3>19. Transfer, Assignment or Delegation</h3>
                    <p>Unless otherwise stated herein, these Terms, and any rights and obligations and licenses granted hereunder, are limited, revocable, non-exclusive and personal to you and therefore may not be transferred, assigned or delegated by you to any third-party without our written consent, but may be transferred, assigned or delegated by us without notice and restriction, including without limitation to any of the entities within the Company group, or to any successor in interest of any business associated with the Company. Any attempted transfer or assignment in violation hereof shall be null and void.</p>
                    <h3>20. Severability</h3>
                    <p>If any provision of these Terms shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect the other provisions of these Terms and all provisions not affected by such invalidity or unenforceability shall remain in full force and effect. Such provision will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any Applicable Laws.</p>
                    <h3>21. Entire Agreement / Translation</h3>
                    <p>These Terms constitute the entire agreement between the parties regarding its subject matter and supersedes and invalidates all other prior representations, arrangements, understandings, and agreements relating to the same subject matter, (whether oral or in writing, express or implied). Each party acknowledges that in agreeing to these Terms it does not rely on any statement, representation, warranty, or understanding other than those expressly set out in these Terms.</p>
                    <p>These Terms are concluded in the English language and all communications including any notices or information being transmitted shall be in English. In the event that these Terms or any part of it is translated (for any proceedings, for your convenience or otherwise) into any other language, the English language text of these Terms shall prevail.</p>
                    <h3>22. Waiver</h3>
                    <p>These Terms shall not be waived in whole or in part except where agreed by the parties in writing. The delay of enforcement or the non-enforcement of any of the terms of these Terms by any party shall not be construed as a waiver of any of the other rights of that party arising out of the breach or any subsequent breach of any of these Terms and no right, power or remedy conferred upon or reserved for any party in these Terms is exclusive of any other right, power or remedy available to that party and each such right, power or remedy shall be cumulative.</p>
                    <h3>23. Notices and Communications</h3>
                    <p>By using the Company services, you agree that we may provide you with notices or other communications, including marketing, relating to your use of the Company electronically: (a) via email (in each case to the address that you provide), SMS message, or telephone call (in each case to the phone number that you provide), or (b) by posting to the Site. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted. You will always be given the option to unsubscribe from receiving any marketing material from us.</p>
                    <p>Notices to us should be sent electronically to team@fridanft.org.</p>
                    <h3>24. Third Party Rights</h3>
                    <p>Other than the entities within the Company group, a person who is not a party to these Terms has no right to enforce any of these Terms.</p>
                    <h3>25. Governing Law and Jurisdiction</h3>
                    <p>These Terms are governed by and shall be construed in accordance with the laws of the Commonwealth of the Bahamas without regard to any choice or conflict of laws rules. Any dispute, controversy, or claim, whether contractual or non-contractual, arising out of or in connection with these Terms, or the breach, termination or invalidity thereof, or any other issue which shall arise in virtue of these Terms, shall be referred to and finally settled by arbitration in accordance with the law of the Commonwealth of the Bahamas under the Rules in force when the Notice of Arbitration is submitted.</p>
                    <p>The law of this arbitration clause shall be Bahamian law. The seat of arbitration shall be in the Commonwealth of the Bahamas. The number of arbitrators shall be one. The arbitration proceedings shall be conducted in the English language.</p>
                </div>

                </div>
            </div>
        </section>
    );
}