import { useState } from "react";


export default function Blog() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const blogs = [
        {
            linkArticle: "https://www.youtube.com/watch?v=uMaz7uyRuBQ&t=1s",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Aug 22, 2022 ",
            readMinutes: "5 min read",
            subject: "The New Art & NFT Model to Raise Charity Funds - Frida.NFT | Ticker Insight Interview"
        },
        {
            linkArticle: "https://www.youtube.com/watch?v=Kwk7BjIn3yE",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Aug 22, 2022 ",
            readMinutes: "9 min read",
            subject: "Caterina Valentino y Martin Mobarak conversan sobre Frida.nft [Caterina Valentino and Martin Mobarak discuss Frida.nft]"
        },
        {
            linkArticle: "https://exeleonmagazine.com/martin-mobarak-keeping-legend-frida-kahlo/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "May 22, 2022 ",
            readMinutes: "8 min read",
            subject: "Martin Mobarak: Keeping the Legend of Frida Kahlo Alive"
        },
        {
            linkArticle: "https://disruptmagazine.com/famous-artist-frida-kahlos-rare-piece-to-become-the-first-perpetual-royalty-charity-initiative-a-founders-story-with-martin-mobarak/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Jul 28, 2022",
            readMinutes: "3 min read",
            subject: "Famous Artist Frida Kahlo’s Rare Piece to Become the First Perpetual Royalty Charity Initiative, A Founder’s Story with Martin Mobarak"
        },
        {
            linkArticle: "https://www.einpresswire.com/article/582076346/famous-artist-frida-kahlo-s-rare-piece-to-become-the-first-perpetual-royalty-charity-initiative",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Jul 27, 2022",
            readMinutes: "2 min read",
            subject: "Famous Artist Frida Kahlo's Rare Piece to Become the First Perpetual Royalty Charity Initiative"
        },
        {
            linkArticle: "https://www.smithsonianmag.com/smart-news/this-man-set-an-alleged-frida-kahlo-drawing-on-fire-then-he-started-selling-its-nft-180981110/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Nov 10, 2022",
            readMinutes: "5 min read",
            subject: "Did This Man Destroy a Frida Kahlo Drawing to Make an NFT? | Smart News| Smithsonian Magazine"
        },
        {
            linkArticle: "https://www.youtube.com/watch?v=9pbRJe-JRoI&t=392s",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Nov 10, 2022",
            readMinutes: "27 min listen",
            subject: "THE ULTIMATE TRUTH BEHIND BURNING OF FRIDA KAHLO PAINTING"
        },
        {
            linkArticle: "https://www.dailymail.co.uk/news/article-11406081/NFT-fanatic-Martin-Mobarak-investigation-BURNING-10million-Frida-Kahlo-drawing.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Nov 10, 2022",
            readMinutes: "8 min read",
            subject: "NFT fanatic Martin Mobarak under investigation after BURNING $10million Frida Kahlo drawing | Daily Mail Online"
        },
        {
            linkArticle: "https://wonderfulengineering.com/this-nft-guy-set-a-priceless-drawing-on-fire-to-mint-10000-nfts-and-only-sold-4/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Nov 9, 2022",
            readMinutes: "3 min read",
            subject: "This NFT Guy Set A Priceless Drawing On Fire To Mint 10,000 NFTs – And Only Sold 4"
        },
        {
            linkArticle: "https://www.iflscience.com/man-burns-frida-kahlo-drawing-to-make-nfts-sells-only-four-sparks-criminal-investigation-66121",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Nov 9, 2022",
            readMinutes: "4 min read",
            subject: "Man Burns Frida Kahlo Drawing To Make NFTs, Sells Only Four, Sparks Criminal Investigation"
        },
        {
            linkArticle: "https://futurism.com/the-byte/priceless-drawing-fire-nfts",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Nov 8, 2022",
            readMinutes: "3 min read",
            subject: "BLOCKCHAIN GUY SETS PRICELESS DRAWING ON FIRE TO MINT 10,000 NFTS, ONLY SELLS FOUR"
        },
        {
            linkArticle: "https://www.theverge.com/2022/11/8/23447636/frida-kahlo-painting-burned-nft-project-sales-martin-mobarak-mexico-investigation",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Nov 8, 2022",
            readMinutes: "2 min read",
            subject: "Burning a Frida Kahlo drawing to sell NFTs is a bad idea"
        },
        {
            linkArticle: "https://www.nytimes.com/2022/11/08/arts/design/frida-kahlo-nft-martin-mobarak.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Nov 8, 2022",
            readMinutes: "7 min read",
            subject: "Setting a Kahlo Drawing Aflame in Search of an NFT Spark"
        },
        {
            linkArticle: "https://www.nydailynews.com/viva/ny-frida-kahlo-interactive-show-brooklyn-immersive-biography-exhibition-art-20221030-tzltx5c7kbgfrdky4u4cry333m-story.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 30, 2022",
            readMinutes: "6 min read",
            subject: "Frida Kahlo’s art and life is subject of immersive Brooklyn exhibit"
        },
        {
            linkArticle: "https://observer.com/2022/10/it-seems-art-destruction-is-a-trend-but-only-time-will-tell-if-its-good/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 29, 2022",
            readMinutes: "12 min read",
            subject: "It Seems Art Destruction Is a Trend but Only Time Will Tell if It’s Good | Observer"
        },
        {
            linkArticle: "https://medium.com/authority-magazine/non-fungible-tokens-martin-mobarak-on-the-5-things-you-need-to-know-to-create-a-highly-successful-606628f22c12",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 20, 2022",
            readMinutes: "16 min read",
            subject: "Non-Fungible Tokens: Martin Mobarak On The 5 Things You Need To Know To"
        },
        {
            linkArticle: "https://www.thedrum.com/opinion/2022/10/19/with-nfts-we-need-augment-the-real-world-not-attack-it",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 19, 2022",
            readMinutes: "7 min read",
            subject: "With NFTs We Need To Augment The Real World, Not Attack It | The Drum"
        },
        {
            linkArticle: "https://blockster.com/frida-kahlo-original-artwork-is-burned-after-being-converted-into-nfts",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 16, 2022",
            readMinutes: "3 min read",
            subject: "Frida Kahlo Original Artwork Is Burned After Being Converted Into NFTs | Blockster"
        },
        {
            linkArticle: "https://www.inferse.com/273398/a-frida-kahlo-drawing-was-destroyed-to-make-nfts-vice/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 15, 2022",
            readMinutes: "5 min read",
            subject: "A Frida Kahlo Drawing Was Destroyed to Make NFTs - VICE"
        },
        {
            linkArticle: "https://english.elpais.com/culture/2022-10-13/will-the-nft-phenomenon-be-nipped-in-the-bud.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 13, 2022",
            readMinutes: "5 min read",
            subject: "Will the NFT phenomenon be nipped in the bud? | Culture | EL PAÍS English Edition"
        },
        {
            linkArticle: "https://www.telegraph.co.uk/news/2022/10/10/damien-hirst-burn-thousands-paintings-owners-refuse-switch-nfts/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 10, 2022",
            readMinutes: "6 min read",
            subject: "Damien Hirst to burn thousands of his paintings in the name of art"
        },
        {
            linkArticle: "https://cablefreetv.org/american-for-advertising-burns-a-unique-painting-by-frida-kahlo/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 6, 2022",
            readMinutes: "3 min read",
            subject: "American for advertising burns a unique painting by Frida Kahlo | CableFree TV"
        },
        {
            linkArticle: "https://forkast.news/nfts-from-art-and-the-burning-question-of-their-physical-origins/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 6, 2022",
            readMinutes: "7 min read",
            subject: "NFTs from art and the burning question of their physical origins"
        },
        {
            linkArticle: "https://www.analyticsinsight.net/launching-nfts-by-burning-artworks-bizarre-transition-into-metaverse/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 6, 2022",
            readMinutes: "2 min read",
            subject: "Launching NFTs by Burning Artworks! Bizarre Transition into Metaverse"
        },
        {
            linkArticle: "https://thesaxon.org/a-florida-man-burned-a-10-million-frida-kahlo-masterpiece-for-publicity-now-he-can-be-arrested/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 6, 2022",
            readMinutes: "4 min read",
            subject: "A Florida man burned a $10 million Frida Kahlo masterpiece for publicity: now he can be arrested - The Saxon"
        },
        {
            linkArticle: "https://www.dazeddigital.com/art-photography/article/57128/1/why-did-this-businessman-martin-mobarak-set-fire-to-frida-kahlo-art-nft",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 6, 2022",
            readMinutes: "6 min read",
            subject: "Why this businessman set fire to Frida Kahlo’s ‘$10m’ art"
        },
        {
            linkArticle: "https://www.collater.al/en/frida-kahlo-painting-burnt-metaverse-art/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 5, 2022",
            readMinutes: "4 min read",
            subject: "Frida Kahlo’s painting burnt and ‘transitioned into the Metaverse’"
        },
        {
            linkArticle: "https://www.miamiherald.com/entertainment/visual-arts/article266851746.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 5, 2022",
            readMinutes: "7 min read",
            subject: "Did a Miami art collector burn a Frida Kahlo drawing to make NFTs? Mexico wants answers"
        },
        {
            linkArticle: "https://www.taylordailypress.net/millionaire-burns-frida-kahlos-10-million-drawing-to-sell-it-as-nft-a-stranger/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 4, 2022",
            readMinutes: "3 min read",
            subject: "Millionaire burns Frida Kahlo’s $10 million drawing to sell it as NFT | a stranger"
        },
        {
            linkArticle: "https://www.instagram.com/reel/CjQ2AC9MO90/?igshid=MDJmNzVkMjY%3D",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 3, 2022",
            readMinutes: "1 min watch",
            subject: "A Mexican businessman says he set fire to a $10 million Frida Kahlo drawing, so he could turn it into an NFT"
        },
        {
            linkArticle: "https://observer.com/2022/10/an-alleged-10-million-frida-kahlo-drawing-was-destroyed-to-sell-a-collection-of-nfts/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 3, 2022",
            readMinutes: "5 min read",
            subject: "An Alleged $10 Million Frida Kahlo Drawing Was Destroyed to Sell a Collection of NFTs | Observer"
        },
        {
            linkArticle: "https://nftplazas.com/frida-kahlo-burning/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 3, 2022",
            readMinutes: "2 min read",
            subject: "Frida Kahlo Burning Event Draws the Beady Eye of the Mexican Authorities"
        },
        {
            linkArticle: "https://www.gearrice.com/update/it-is-an-attack-against-art-but-it-has-quadrupled-its-value/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 2, 2022",
            readMinutes: "4 min read",
            subject: "it is an attack against art, but it has quadrupled its value - Gearrice"
        },
        {
            linkArticle: "https://www.msn.com/en-us/money/markets/video-shows-millionaire-burning-10-million-frida-kahlo-historical-artwork-to-sell-as-nft/ss-AA12trKu",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Oct 1, 2022",
            readMinutes: "1 min read",
            subject: "Video Shows Millionaire Burning $10 Million Frida Kahlo Historical Artwork to Sell as NFT"
        },
        {
            linkArticle: "https://www.independent.co.uk/news/world/americas/businessman-frida-kahlo-drawing-burned-b2180355.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Oct 1, 2022",
            readMinutes: "3 min read",
            subject: "Businessman who said he burned a $10m Frida Kahlo drawing is under criminal investigation | The Independent"
        },
        {
            linkArticle: "https://www.thestreet.com/crypto/news/frida-kahlo-nonfungible-tokens-mint-10000-nfts-mexico",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 30, 2022",
            readMinutes: "2 min read",
            subject: "Did Someone Burn A Frida Kahlo Drawing to Mint 10,000 NFTs?"
        },
        {
            linkArticle: "https://hyperallergic.com/765443/collector-who-burned-frida-kahlo-work-for-nft-under-investigation/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Sep 30, 2022",
            readMinutes: "7 min read",
            subject: "Collector Who Says He Burned Frida Kahlo Work for NFT Under Investigation"
        },
        {
            linkArticle: "https://www.thetimes.co.uk/article/was-frida-kahlo-drawing-burnt-in-digital-nft-stunt-mexico-wants-the-truth-8kwsfcdm0",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 29, 2022",
            readMinutes: "1 min read",
            subject: "Did businessman really burn a £10m Kahlo artwork? Mexico wants the truth | World | The Times"
        },
        {
            linkArticle: "https://faroutmagazine.co.uk/mexican-authorities-investigate-collector-frida-kahlo-nft/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Sep 29, 2022",
            readMinutes: "3 min read",
            subject: "Mexican authorities investigate collector who destroyed Frida Kahlo drawing to promote NFT"
        },
        {
            linkArticle: "https://www.telegraph.co.uk/world-news/2022/09/29/cryptocurrency-entrepreneur-says-destroyed-10-million-frida/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 29, 2022",
            readMinutes: "5 min read",
            subject: "Cryptocurrency entrepreneur destroyed $10m Frida Kahlo drawing to ‘transition it into the Metaverse’"
        },
        {
            linkArticle: "https://news.yahoo.com/businessman-said-burned-10m-frida-001738020.html?guccounter=1",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Sep 29, 2022",
            readMinutes: "3 min read",
            subject: "Businessman who said he burned a $10m Frida Kahlo drawing is under criminal investigation"
        },
        {
            linkArticle: "https://english.elpais.com/international/2022-09-30/a-millionaire-burned-a-drawing-by-frida-kahlo.html",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 29, 2022",
            readMinutes: "3 min read",
            subject: "Millionaire burns Frida Kahlo drawing to sell it as NFT"
        },
        {
            linkArticle: "https://www.theartnewspaper.com/2022/09/28/collector-martin-mobarak-burning-frida-kahlo-nft-mexico-investigation",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Sep 29, 2022",
            readMinutes: "3 min read",
            subject: "Mexican government investigating collector who claims he burned a Frida Kahlo drawing for an NFT charity project"
        },
        {
            linkArticle: "https://news.artnet.com/art-world/did-this-man-really-burn-a-10-million-frida-kahlo-drawing-on-camera-to-sell-nfts-our-frame-by-frame-analysis-of-the-youtube-documentation-2183391",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 28, 2022",
            readMinutes: "10 min read",
            subject: "Did This Man Really Burn a $10 Million Frida Kahlo on Camera Just to Sell NFTs? We Did a Frame-by-Frame Analysis of the YouTube Video | Artnet News"
        },
        {
            linkArticle: "https://www.vice.com/en/article/aken7k/rida-kahlo-nft-mexico",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Sep 28, 2022",
            readMinutes: "5 min read",
            subject: "VICE NEWS:  A Frida Kahlo painting was destroyed to make NFTs: "
        },
        {
            linkArticle: "https://nypost.com/2022/09/27/rare-frida-kahlo-drawing-torched-in-nft-sale/amp/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 28, 2022",
            readMinutes: "5 min read",
            subject: "Millionaire Martin Mobarak probed for torching $10 M Frida Kahlo painting in NFT stunt"
        },
        {
            linkArticle: "https://upjobsnews.com/they-burned-frida-kahlos-work-valued-at-millions-of-dollars-to-sell-it-in-nft-more-latest-news-here/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Sep 26, 2022",
            readMinutes: "6 min read",
            subject: "They burned Frida Kahlo's work valued at millions of dollars to sell it in NFT & More Latest News Here"
        },
        {
            linkArticle: "https://epodcastnetwork.com/transforming-the-art-charity-nft-and-health-sector-through-frida-nft/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Sep 1, 2022",
            readMinutes: "2 min read",
            subject: "Transforming the Art, Charity, NFT and Health Sector through Frida.NFT"
        },
        {
            linkArticle: "https://miamiindependent.com/philanthropist-burns-a-rare-frida-kahlo-piece-to-make-it-eternal-only-in-the-nft-world/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Aug 29, 2022",
            readMinutes: "3 min read",
            subject: "PHILANTHROPIST BURNS A RARE FRIDA KAHLO PIECE TO MAKE IT ETERNAL ONLY IN THE NFT WORLD - Miami Independent"
        },
        {
            linkArticle: "https://creativedestructionmedia.com/news/business/2022/08/29/philanthropist-burns-a-rare-frida-kahlo-piece-to-make-it-eternal-only-in-the-nft-world/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Aug 29, 2022",
            readMinutes: "5 min read",
            subject: "PHILANTHROPIST BURNS A RARE FRIDA KAHLO PIECE TO MAKE IT ETERNAL ONLY IN THE NFT WORLD - CD Media"
        },
        {
            linkArticle: "https://exeleonmagazine.com/martin-mobarak-keeping-legend-frida-kahlo/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Aug 1, 2022",
            readMinutes: "8 min read",
            subject: "Martin Mobarak: Keeping the Legend of Frida Kahlo Alive"
        },
        {
            linkArticle: "https://disruptmagazine.com/famous-artist-frida-kahlos-rare-piece-to-become-the-first-perpetual-royalty-charity-initiative-a-founders-story-with-martin-mobarak/",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 35%, rgba(120,81,236,1) 100%)",
            date: "Jul 29, 2022",
            readMinutes: "5 min read",
            subject: "Famous Artist Frida Kahlo’s Rare Piece to Become the First Perpetual Royalty Charity Initiative, A Founder’s Story with Martin Mobarak"
        },
        {
            linkArticle: "https://www.linkedin.com/posts/tickeroriginals_the-new-art-nft-model-to-raise-charity-ugcPost-6958267313896792064-dEN8/?utm_source=share&utm_medium=member_desktop",
            gradient: "linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(120,81,236,0.5508797268907564) 66%, rgba(97,91,89,0.8505996148459384) 100%)",
            date: "Jul 27, 2022",
            readMinutes: "5 min read",
            subject: "The New Art Nft Model To Raise Charity[TICKER NEWS LIVE]"
        },
    ];

    const goToPrevious = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? blogs.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
    const goToNext = () => {
      const isLastSlide = currentIndex === blogs.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);     
    };


    return (
        <section className="our-blog">
            <div className="container">
            <div className="background"></div>
                <div className="grid columns-12">
                <p className="over-title">UPDATES</p>
                <h1 className="title">MEDIA</h1>
                <p className="sub-title">A NEWS FLASH</p>
                <div className="blogs">
                            <div className="blog">
                                <a href={blogs[currentIndex].linkArticle} >
                                    <div className="card-background">
                                        <div style={{background: blogs[currentIndex].gradient}} className="card-gradient"></div>
                                    </div>
                                </a>
                                <div className="content">
                                    <div className="timestamp">
                                        <p className="date">{blogs[currentIndex].date}</p>
                                        <p className="read-minutes">{blogs[currentIndex].readMinutes}</p>
                                    </div>
                                    <p className="subject">{blogs[currentIndex].subject}</p>
                                    <p className="read-more">Read More</p>
                                </div>
                            </div>

                            <div className="blog blog-2">
                                <a href={currentIndex <= 49 ? (blogs[currentIndex+1].linkArticle) : (blogs[0].linkArticle) } >
                                    <div className="card-background">
                                        <div style={currentIndex <= 49 ? {background: blogs[currentIndex+1].gradient} : {background: blogs[0].gradient}} className="card-gradient"></div>
                                    </div>
                                </a>
                                <div className="content">
                                    <div className="timestamp">
                                        <p className="date">{currentIndex <= 49 ? blogs[currentIndex+1].date : blogs[0].date}</p>
                                        <p className="read-minutes">{currentIndex <= 49 ? blogs[currentIndex+1].readMinutes : blogs[0].readMinutes }</p>
                                    </div>
                                    <p className="subject">{currentIndex <= 49 ? blogs[currentIndex+1].subject : blogs[0].subject}</p>
                                    <p className="read-more">Read More</p>
                                </div>
                            </div>

                            <div className="blog blog-3">
                                <a href={currentIndex <= 50 ? blogs[currentIndex+2].linkArticle : blogs[1].linkArticle} >
                                    <div className="card-background">
                                        <div style={currentIndex <= 50 ? {background: blogs[currentIndex+2].gradient} : {background: blogs[1].gradient}} className="card-gradient"></div>
                                    </div>
                                </a>
                                <div className="content">
                                    <div className="timestamp">
                                        <p className="date">{currentIndex <= 50 ? blogs[currentIndex+2].date : blogs[1].date}</p>
                                        <p className="read-minutes">{currentIndex <= 50 ? blogs[currentIndex+2].readMinutes : blogs[1].readMinutes}</p>
                                    </div>
                                    <p className="subject">{currentIndex <= 50 ?  blogs[currentIndex+2].subject : blogs[1].subject}</p>
                                    <p className="read-more">Read More</p>
                                </div>
                            </div>
                    </div>

                    <div className="buttons">
                        <button className="left-button" onClick={goToPrevious}>
                            <img src="./images/arrow.png"></img>
                        </button>
                        <button className="right-button" onClick={goToNext}>
                            <img src="./images/arrow.png"></img>
                        </button>
                    </div>

                </div>
            </div>
        </section>
    );
}