export default function InfoLinks() {

    const boxesInfo = [
        {
            imagePath: "./images/whitepaper-boxes.png",
            link: "https://fridanft.gitbook.io//",
            title: "Whitepaper",
            sub_title: " Frida.NFT creates a bridge between historical art & the expanding potential of Web 3.0 to reform the charity industry."
        },
        {
            imagePath: "./images/certificate-boxes.png",
            link: "https://fridanft.org/docs/Authentication_Cert.pdf", 
            title: "Opinion of Authenticity",
            sub_title: "Analysis performed by Andres Siegel, official Frida Kahlo's curator."
        },
        {
            imagePath: "./images/paint-boxes.png",
            link: "https://fridanft.org/docs/FridaKahlo_Fantasmones_English.pdf",
            title: "Study of the Painting",
            sub_title: "An official study of the painting including exhibition history."
        },
        {
            imagePath: "./images/blueprint-boxes.png",
            link: "https://fridanft.org/docs/FridaKahlo_Fantasmones_Spanish.pdf",
            title: "Estudio de Obra",
            sub_title: "Painting analysis documentation in Spanish language"
        },
    ];


    return (
        <section className="info-links">
            <div className="container">
                <div className="background"></div>
                <div className="grid columns-12">
                <p className="over-title">INFORMATIONS ABOUT</p>
                <h1 className="title">THE PROJECT</h1>
                <p className="sub-title">READ BEFORE YOU PROCEED</p>
                <div className="boxes">
                        {boxesInfo.map((box) => (
                            <a href={box.link} key={box.title}> 
                                <div className="box">
                                <div className="background">

                                </div>
                                <div className="image">
                                    <div>
                                        <img src={box.imagePath} ></img>
                                    </div>
                                </div>
                                <div className="content">
                                    <p className="title-content">{box.title}</p>
                                    <p className="sub-title-content">{box.sub_title}</p>
                                </div>
                            </div> 
                            </a>
                        ))}
                    </div>

                </div>
            </div>
        </section>
    );
}