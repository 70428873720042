import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export default function Subscribe() {

    const [status, setStatus] = useState({
        sent: null,
        message: null,
    });

    const [email, setEmail] = useState("");
    const form = useRef();

    const clearInputs = () => {
        setEmail('');
    }


    const sendEmail = (e) => {

        if (email != null && email != '') {


            const service_id = 'service_djgwgx7';
            const template_id = 'template_6lh27ek';
            const public_key = 'dTeB6fbwdNu_8tfc1';

            emailjs.init(public_key);

            emailjs.sendForm(service_id, template_id, form.current, public_key)
                .then((result) => {
                    console.log(result.text);
                    setStatus({
                        sent: true,
                        message: 'Email has been sent!'
                    });
                }, (error) => {
                    setStatus({
                        sent: false,
                        message: 'Email has not been sent!'
                    });
                });


        } else {
            setStatus({
                sent: false,
                message: 'Please complete all required filds.'
            });
        }

    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    };

    return (
        <section className="subscribe">
            <div className="container">
                <div className="grid columns-12">
                    <h1 className="title">SUBSCRIBE</h1>
                    <div className="sub-title">
                        <p>BE THE FIRST TO GET THE LATEST NEWS AND EXCLUSIVE OFFERS!</p>
                    </div>
                    <div className="contacts-box">
                        <div className="contact-box-content">
                            <div className='contact-form'>
                                <form className="contact-box-content-form" ref={form} onSubmit={sendEmail}>
                                    <div className="content-name-email">
                                        <input className={ (email == '' || email == null) ? 'empty': '' } aria-label="Your email" type="email" name="email_address" placeholder="user@example.com" onChange={handleEmailChange} value={email} />
                                    </div>
                                    <div className='buttons-div'>
                                        <button className="button gradient content-send ">
                                            <h4>Subscribe</h4>
                                        </button>
                                    </div>
                                    {status.sent !== null && (
                                        <h4 style={{ color: status.sent === true ? 'green' : 'red' }}>{status.message}</h4>
                                    )}
                                </form>

                            </div>
                        </div>
                         
                    </div>
                </div>
            </div>
        </section>
    );

}