export default function Faq() {
  const questions = [
    {
      question: "NFTs - what are they?",
      answer:
        "In a blockchain, a non-fungible token (NFT) is an identifier that cannot be copied, substituted, or divided.",
      id: 0,
    },
    {
      question: "How many pieces are included in this NFT collection?",
      answer:
        "The NFTs are divided into 3 categories: 300 Genesis Founders, 1000 Deltas, and 8700 Enamorates.",
      id: 1,
    },
    {
      question: "Is the physical painting still there?",
      answer:
        "July 30th, 2022 marked the permanent transition of the painting into the Metaverse.",
      id: 2,
    },
    {
      question: "Is there proof that the original painting has been altered?",
      answer:
        "The chain of custody was fully documented. The painting was certified for authenticity on July 30th,2022, and immediately placed in the custody of Guardian Professional Security. Fantasmones Siniesteros was certified authentic by the top Frida Kahlo curator, Andres Siegel. The painting was held in Guardian Professional Security’s vault until the date of our event. Joe Harmon, Director of Guardian Security, delivered the piece on July 30th, 20222. He verified that the painting was in their possession, under strict vault security, from the date of pickup to delivery. Recordings of the event, with 200+ attendees witnessing this historic moment, can be viewed at FridaNFT.org. Check the Certificate of Authenticity and Guardian Security invoice.",
      id: 3,
    },
  ];

  const questionActivation = (i) => {
    const anim = document.querySelectorAll("#question-activ");

    anim[i].classList.toggle("active");
  };

  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="grid columns-12">
          <div className="titles">
            <p className="over-title"></p>
            <h1 className="title">FREQUENTLY ASKED QUESTIONS</h1>
            <p className="sub-title">ENJOY! THE LATEST HOT QUESTIONS</p>
          </div>
          <div className="questions">
            {questions.map((quest) => (
              <div key={quest.answer}>
                <div
                  className="question"
                  onClick={() => {
                    questionActivation(quest.id);
                  }}
                >
                  <p>{quest.question}</p>
                </div>
                <div className="answer" id={"question-activ"}>
                  <p>{quest.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
