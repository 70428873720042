import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {

    const [status, setStatus] = useState({
        sent: null,
        message: null,
    });

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const form = useRef();

    const clearInputs = () => {
        setEmail('');
        setName('');
    }


    const sendEmail = (e) => {

        if (email != null && email != '' && name != null && name != '') {


            const service_id = 'service_djgwgx7';
            const template_id = 'template_woqs9zc';
            const public_key = 'dTeB6fbwdNu_8tfc1';

            emailjs.init(public_key);

            emailjs.sendForm(service_id, template_id, form.current, public_key)
                .then((result) => {
                    console.log(result.text);
                    setStatus({
                        sent: true,
                        message: 'Email has been sent!'
                    });
                }, (error) => {
                    setStatus({
                        sent: false,
                        message: 'Email has not been sent!'
                    });
                });


        } else {
            setStatus({
                sent: false,
                message: 'Please complete all required filds.'
            });
        }

    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleNameChange = (event) => {
        const { value } = event.target;
        setName(value);
    };

    const handleSubjectChange = (event) => {
        const { value } = event.target;
        setSubject(value);
    }

    const handleDescriptionChange = (event) => {
        const { value } = event.target;
        setDescription(value);
    }

    return (
        <section className="contact">
            <div className="container">
                <div className="grid columns-12">
                    <h1 className="title">CONTACT</h1>
                    <div className="sub-title">
                        <p>Home </p>
                        <p> / </p>
                        <p> Contact</p>
                    </div>
                    <div className="contacts-box">
                        <div className="contact-box-content">
                            <h1 className="content-title">Write us your message</h1>
                            <div className='enquire'>
                                <div className='enquire-email'>
                                    <div className='enquire-email-general-image'>
                                        <img src="./images/email.png"></img>
                                    </div>
                                    <h3 className='enquire-title'>For general enquire: <a className='enquire-title-email' href="mailto:team@fridanft.org">team@fridanft.org</a></h3>
                                </div>
                                <div className='enquire-email-pr'>
                                    <div className='enquire-email-pr-image'>
                                        <img src="./images/email-pr.png"></img>
                                    </div>
                                    <h3 className='enquire-title'>For public relations enquire: <a className='enquire-title-email' href="mailto:melike.nyc@gmail.com">Melike Ayan</a></h3>
                                </div>
                            </div>
                            <div className='enquire-adress'>
                                <div className='enquire-adress-image'>
                                    <img src="./images/home.png"></img>
                                </div>
                                <p className='enquire-adress-text'>Exuma Fortress Ltd, Condor House, 19 West Street North, Nassau, Bahamas</p>
                            </div>
                            <div className='contact-form'>
                                <form className="contact-box-content" ref={form} onSubmit={sendEmail}>
                                    <div className="content-name-email">
                                        <input className={ (name == '' || name == null) ? 'empty': '' } aria-label="Your full name" type="text" name="first_name" placeholder="Full Name" onChange={handleNameChange} value={name} />
                                        <input className={ (email == '' || email == null) ? 'empty': '' } aria-label="Your email" type="email" name="email_address" placeholder="user@example.com" onChange={handleEmailChange} value={email} />
                                    </div>
                                    <div className="content-subject">
                                        <input className={ (subject == '' || subject == null) ? 'empty': '' } type="text" name="subject" placeholder="Subject" onChange={handleSubjectChange} value={subject} />
                                    </div>
                                    <div className="content-message">
                                        <input className={ (description == '' || description == null) ? 'empty': '' } type="text" name="message" placeholder="Enter your message" onChange={handleDescriptionChange} value={description} />
                                    </div>
                                    <div className='buttons-div'>
                                        <button className="button gradient content-send black">
                                            <h4>Send message</h4>
                                        </button>
                                    </div>
                                    {status.sent !== null && (
                                        <h4 style={{ color: status.sent === true ? 'green' : 'red' }}>{status.message}</h4>
                                    )}
                                </form>

                            </div>
                        </div>
                        <div className="contact-box-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3613.796851050464!2d-77.34882824333815!3d25.074873702086705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sCondor%20House%2019%20West%20Street%20North%20Nassau%2C%20Bahamas!5e0!3m2!1sro!2sro!4v1667842839708!5m2!1sro!2sro"
                                frameBorder="0"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}