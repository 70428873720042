export default function Roadmap() {


    return (
        <section className="roadmap">
            <div className="container">
                <div className="grid columns-12">
                    <p className="over-title"></p>
                    <h1 className="title">ROADMAP</h1>
                    <p className="sub-title">PASS THROUGH VARIOUS PHASES</p>
                    <div className="timeline-parcours">
                        <p className="phase-title">PHASE 1</p>
                        <div className="timeline-top-info">
                            <div className="timeline-top-box">
                                <p>Frida.NFT is introduced worldwide through exclusive interviews, AMAs, partner sneak peeks, & special guest interviews.</p>
                                <p>Minting will take place in waves. Staggering the process will help ensure an efficient & friendly user experience.</p>
                                <p>Our community will kick off shortly after mint in a luxurious South Florida mansion with a curated list of attendees. This historic event will be followed by an incredible "phygital" experience during Art Basel Miami.</p>
                            </div>
                        </div>
                        <div className="timeline-principal">
                            <div className="timeline-bullets">
                                <div className="timeline-bullet"></div>
                                <div className="timeline-bullet"></div>
                                <div className="timeline-bullet"></div>
                            </div>
                            <div className="timeline-line"></div>
                        </div>
                        <p className="phase-title">PHASE 2</p>
                        <div className="timeline-bottom-left-info">
                            <div className="timeline-left-box">
                                <p>The minted NFTs meta-data is updated to reveal the full artwork & rarity tiers (Higher rarity = increased benefits) Rarity will be as follows: First 100 will be Genesis holders and the other 9,900 will be released in 4 tiers of Fantasmones NFTs.</p>
                                <p>Several giveaways will be held for NFT holders. Giveaway items may include real-world items, digital items, and exclusive experiences.</p>
                                <p>Announcing several Frida.NFT partners & collaborators, including at least one tier 1 partnership.</p>
                                <p>Introduction to rewards & benefits for Frida NFT holders.</p>
                            </div>
                        </div>
                        <p className="phase-title">PHASE 3</p>
                        <div className="timeline-bottom-right-info">
                            <div className="timeline-right-box">
                                <p>During the event, renowned art exhibits, timeless masterpieces, & live art creations will be exhibited for community members to enjoy.</p>
                                <p>A complete calendar of digital and physical events, experiences, and auctions along with a roadmap 2.0.</p>
                                <p>Frida.NFT holders will receive additional membership benefits and perks.</p>
                                <p>During Art Basel Miami, a digital experience will follow this historic event. For our second event, you must hold a Fantasmones NFT.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}