import { ReactPhotoSphereViewer, CompassPlugin, MarkersPlugin } from 'react-photo-sphere-viewer';
import React from 'react';


export default function MemberBenefits() {

    const photoSphereRef = React.useRef();

    const handleClick = () => {
      photoSphereRef.current.animate({
        latitude: 0,
        longitude: 0,
        zoom: 55,
        speed: '10rpm',
      });
    }

    const plugins = [
        [CompassPlugin, {
          hotspots: [
            { longitude: '0deg' },
            { longitude: '90deg' },
            { longitude: '180deg' },
            { longitude: '270deg' },
          ],
        }],
        [MarkersPlugin, {
            markers: [
		
                {
        
                  id: 'txt1',
                  x: 1050,
                  y: 1110,
                  image: 'https://fridanft.org/assets/img/textlink1.png',
                  anchor: 'center center',
                  scale: [0.5, 1.5],
                  width: 400,
                  height: 200,
                    tooltip: {
                    content: "<b>Genesis holders will guide the project's direction through voting on major roadmap initiatives & choosing which charities receive a portion of the donations. All members receive access to: <li>Curated AMA's, 1-on-1's, and discussions with renowned artists <li>Incubation channels & success enabling tools <li>Various discounts among premier partners ",

                    position: 'right'
                    }
                                                
                },
                                    
                {
        
                  id: 'txt2',
                  x: 150,
                  y: 880,
                  image: 'https://fridanft.org/assets/img/textlink4.png',
                  anchor: 'center center',
                  scale: [0.5, 1.5],
                  width: 400,
                  height: 200,
                    tooltip: {
                    content: "<b>A percent of ALL proceeds are donated directly to our charity partner organizations. <br> Blockchain technology allows for full transparency by building the donations into the smart contract. The initial sale and all secondary sales will automatically donate a portion of the <br> proceeds to our partner charity organizations. ",

                    position: 'right'
                    }
                                                
                },
        
                {
        
                  id: 'txt3',
                  x: 3060,
                  y: 1100,
                  image: 'https://fridanft.org/assets/img/textlink3.png',
                  anchor: 'center center',
                  scale: [0.5, 1.5],
                  width: 400,
                  height: 200,
                    tooltip: {
                    content: "<b>Experience more with massive events, galas, and Phygital experiences in exotic locations. The first event kicks off shortly after mint in a luxurious South Florida mansion with a curated list of attendees for the first 150 holders of the genesis collection.  This historic event will be followed by an incredible phygital experience during Art Basel Miami.",

                    position: 'right'
                    }
                                                
                },							
                {
        
                  id: 'txt4',
                  x: 2000,
                  y: 890,
                  image: 'https://fridanft.org/assets/img/textlink2.png',
                  anchor: 'center center',
                  scale: [0.5, 1.5],
                  width: 400,
                  height: 200,
                    tooltip: {
                    content: "<b>Display your NFT collection in a personal ultra-realistic metaverse showroom. Members can access their digital gallery anytime, invite guests, and build as they please.  Appreciate your art on another level by stepping into each painting to see, hear, and experience the masterpiece as if it was your own reality.",

                    position: 'right'
                    }
                                                
                }
            ],
        }],
      ]
  

    return (
        <section className="member-benefits">
            <div className="container">
                <div className="grid columns-12">
                    <div className="titles">
                        <p className="over-title">COLLECTIONS</p>
                        <h1 className="title">EXPLORE OUR MEMBER BENEFITS BELOW</h1>
                        <p className="sub-title">EXCLUSIVE MEMBER BENEFITS</p>
                    </div>

            <div className='viewer'>
                 <ReactPhotoSphereViewer src="./images/3dviewmembers.png" plugins={plugins} height={'100vh'} width={"100%"}></ReactPhotoSphereViewer>
            </div>
                </div>
            </div>
        </section>
    );
}